/* eslint-disable */
import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".lightgallery");
  if (!elms || elms.length == 0) {
    return;
  }

  deps.load("lightgallery").then( () => {
    for (let element of elms) {
      let config = null;
      if (element.dataset.config) {
        config = element.dataset.config;
        element.removeAttribute("data-config");
      }

      lightGallery(element, JSON.parse(config));
    }

    const exposeBtn = document.querySelector("#exGalleryBtn");
    if (exposeBtn) {
      exposeBtn.addEventListener("click", function(e) {
        e.preventDefault();
        const gallery = document.querySelector("#exGallery");
        if (gallery) {
          gallery.firstElementChild.click();
        }
      })
    }

    const iframeElms = document.querySelectorAll(".iframe-gallery");
    if (iframeElms) {
        for (let element of iframeElms) {
            lightGallery(element, {
                selector: "a.iframe-link"
           });
        }
    }
  });
}
